<template>
  <country-details />
</template>

<script>
import countryDetails from "@/components/countryDetails.vue";
export default {
  name: "Country",
  components: { countryDetails },
};
</script>

<style lang="scss" scoped></style>
